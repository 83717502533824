/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Field, Formik } from 'formik';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  FaInfoCircle,
  FaRegMinusSquare,
  FaRegPlusSquare,
} from 'react-icons/fa';
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { getGeographicalCoordinates } from '../../functions/googleMap';
import { StyledTooltip } from '../../global.css';
import { CheckboxSet } from '../../helpers/checkbox-set';
import { useNavigation } from '../../helpers/navigate';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import Autocomplete from '../autocomplete/autocomplete';
import { getSelectedConditions } from '../find-a-study/conditions-filtering';
import { formInitialValues } from '../find-a-study/constants';
import DisplayModalConditions from '../modal/display-conditions';
import Modal from '../modal/modal';
import { AdvancedStyle } from './advanced-search.css';
import { useParseUrl } from './use-parse-url';
import { masterDataContext } from '../../store/masterDataContext';

const AdvancedSearch = ({ params, data, searchCallBack, pageContext }) => {
  const [isWeCanHelpOpen, setIsWeCanHelpOpen] = useState(false);
  const [isAgeOpen, setIsAgeOpen] = useState(false);
  const [isGenderOpen, setIsGenderOpen] = useState(false);
  const [isPhasesOpen, setIsPhasesOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [isStudyTypeDropdownOpen, setIsStudyTypeDropdownOpen] = useState(false);
  const [isCollaboratorDropdown, setIsCollaboratorDropdown] = useState(false);
  const [resetKey, setResetKey] = useState(false);
  const countryRef = useRef('');
  const studyTypeRef = useRef('');
  const collaboratorRef = useRef('');
  const keySuggestionRef = useRef([]);
  const textInputRef = useRef(null);
  const additionalFilters = data.additionalFiltersJson;
  const { masterData } = useContext(masterDataContext);
  const navigate = useNavigation(`/${pageContext.langKey}/studies/`);
  const urlParsedParams = useParseUrl(params);
  const searchedParams = Object.assign({}, formInitialValues, urlParsedParams);

  useEffect(() => {
    let keySuggestion = [];
    if (typeof window !== 'undefined') {
      typeof masterData?.CONDITION !== 'undefined' &&
        masterData?.CONDITION.length > 0 &&
        masterData?.CONDITION.forEach(element => {
          keySuggestion.push(element.DisplayValue);
          if (element.Children) {
            element.Children.forEach(subElement =>
              keySuggestion.push(subElement.DisplayValue)
            );
          }
        });
      typeof masterData?.COUNTRY !== 'undefined' &&
        masterData?.COUNTRY.length > 0 &&
        masterData?.COUNTRY.forEach(element => {
          keySuggestion.push(element.DisplayValue);
          if (element.Children) {
            element.Children.forEach(subElement =>
              keySuggestion.push(subElement.DisplayValue)
            );
          }
        });
      const uniqueSuggestions = keySuggestion.filter(
        (suggestion, index, array) => array.indexOf(suggestion) === index
      );
      keySuggestionRef.current = uniqueSuggestions;
    }
  }, [textInputRef]);

  useEffect(() => {
    if (params.country.length > 0) {
      let country = masterData?.COUNTRY?.find(item => {
        return item.InternalValue.includes(params.country);
      });
      countryRef.current = country.DisplayValue;
    }
    if (params.StudyTypes.length > 0) {
      let StudyType = masterData?.STUDY_TYPE?.find(item => {
        return item.InternalValue.includes(params.StudyTypes);
      });
      studyTypeRef.current = StudyType.DisplayValue;
    }
    if (params.Collaborators.length > 0) {
      let Collaborator = masterData?.COLLABORATOR?.find(item => {
        return item.InternalValue.includes(params.Collaborators);
      });
      collaboratorRef.current = Collaborator.DisplayValue;
    }
  }, []);

  function handleReset(values) {
    navigate.withParams(values);
    if (searchCallBack && typeof searchCallBack === 'function') {
      setTimeout(() => {
        searchCallBack();
        setResetKey(false);
      });
    }
  }

  function handleSubmit(values) {
    navigate.withParams(values);
    if (searchCallBack && typeof searchCallBack === 'function') {
      setTimeout(() => {
        searchCallBack();
      });
    }
  }

  return (
    <AdvancedStyle>
      {params && (
        <Formik
          initialValues={searchedParams}
          onSubmit={(values, actions) => {
            if (
              values.country &&
              values.country.length &&
              values.zip &&
              values.zip.length
            ) {
              getGeographicalCoordinates(
                values.country.replace('COUNTRY_', ''),
                values.zip && values.zip.length ? values.zip : ''
              )
                .then(response => {
                  switch (response.tag) {
                    case 'Success': {
                      const { Longitude, Latitude } = response.value;
                      values.Latitude = Latitude;
                      values.Longitude = Longitude;
                      return true;
                    }
                    case 'Error': {
                      /*eslint quotes: [2, "single", "avoid-escape"]*/
                      alert(
                        typeof response.value === 'string' &&
                          response.value === 'Not found'
                          ? 'Invalid zip code and country combination'
                          : "We can't find specified address"
                      );
                      return false;
                    }
                  }
                })
                .then(success => {
                  actions.setSubmitting(false);
                  if (!success) {
                    return false;
                  }
                  handleSubmit(values);
                });
            } else {
              handleSubmit(values);
              actions.setSubmitting(false);
            }
          }}
          enableReinitialize={true}
          render={({ values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <h3>
                {'Filter'}
                <img src={'/icons/filter-white.png'} alt="filter" />
              </h3>
              <p className={'info-text'}>
                {'Use filter below to reduce the number of studies'}
              </p>
              <h5 className={'keyword-search'}>
                {'Keyword search'}
                <FaInfoCircle id="keyword_search" />
              </h5>
              <StyledTooltip
                trigger="hover"
                placement="top"
                target="keyword_search"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.findAStudyJson.searchTooltip.childMarkdownRemark
                        .html,
                  }}
                />
              </StyledTooltip>

              <Autocomplete
                refs={textInputRef}
                className={'keySearch'}
                placeholder={'Enter keyword'}
                suggestions={keySuggestionRef.current}
                name="SearchTerm"
                changeValue={value => {
                  setFieldValue('SearchTerm', value);
                }}
                userInput={values.SearchTerm}
                reset={resetKey}
              />

              {typeof masterData?.CONDITION !== 'undefined' &&
                masterData?.CONDITION.length > 0 && (
                  <>
                    <h5>{'Condition / Disease'}</h5>
                    <Modal
                      conditions={masterData?.CONDITION}
                      openedModal={isModalOpened}
                      showModal={() => setIsModalOpened(true)}
                      hideModal={() => setIsModalOpened(false)}
                      conditionsList={values.Conditions}
                      conditionVal={getSelectedConditions(
                        masterData?.CONDITION,
                        values.Conditions
                      )}
                    >
                      <Row>
                        <div className={'conditionTitle'}>
                          <h5>{additionalFilters.modalCondition.title}</h5>
                        </div>
                        <DisplayModalConditions
                          conditions={masterData?.CONDITION}
                        ></DisplayModalConditions>
                        <div className={'modalFooter'}>
                          <div className={'healthy-boxes'}>
                            {typeof masterData?.HEALTHY_VOL !== 'undefined' &&
                              masterData?.HEALTHY_VOL?.length > 0 && (
                                <>
                                  <p>
                                    {
                                      additionalFilters.modalCondition
                                        .healthyVolunteers
                                    }
                                  </p>
                                  <div className={'healthy-options'}>
                                    <CheckboxSet
                                      list={masterData?.HEALTHY_VOL}
                                      name="HealthyVolunteer"
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className={'right'}>
                            {values.Conditions.length > 0 ? (
                              <span>
                                {values.Conditions.length +
                                  additionalFilters.modalCondition
                                    .selectedCondition}
                              </span>
                            ) : (
                              ''
                            )}
                            <button
                              type="button"
                              className={'clearBtn'}
                              onClick={() => {
                                setFieldValue('Conditions', []);
                                setFieldValue('HealthyVolunteer', []);
                              }}
                            >
                              <p>{additionalFilters.modalCondition.clearBtn}</p>
                            </button>
                            <button
                              type="button"
                              onClick={() => setIsModalOpened(false)}
                              className={'buttonOk'}
                            >
                              {additionalFilters.modalCondition.okBtn}
                            </button>
                          </div>
                        </div>
                      </Row>
                    </Modal>
                  </>
                )}

              {typeof masterData?.STUDY_TYPE !== 'undefined' &&
                masterData?.STUDY_TYPE?.length > 0 && (
                  <>
                    <h5>{additionalFilters.studyType}</h5>
                    <Dropdown
                      isOpen={isStudyTypeDropdownOpen}
                      toggle={() =>
                        setIsStudyTypeDropdownOpen(!isStudyTypeDropdownOpen)
                      }
                    >
                      <DropdownToggle
                        tag="button"
                        type="button"
                        name="StudyTypes"
                        className={'select-dropdown'}
                      >
                        <p>{studyTypeRef.current || 'Select Type'}</p>
                        <span className={'arrowRight'}></span>
                      </DropdownToggle>
                      <DropdownMenu
                        flip={false}
                        direction="down"
                        style={{
                          maxHeight: '300px',
                          overflow: 'auto',
                        }}
                      >
                        {[
                          {
                            InternalValue: '',
                            DisplayValue: 'Select Type',
                          },
                        ]
                          .concat(masterData?.STUDY_TYPE)
                          .map((studyType, index) => (
                            <DropdownItem
                              className={
                                studyTypeRef.current ===
                                  studyType.DisplayValue ||
                                (studyTypeRef.current === '' && index === 0)
                                  ? 'selected'
                                  : ''
                              }
                              onClick={() => {
                                setFieldValue(
                                  'StudyTypes',
                                  studyType.InternalValue
                                );
                                studyTypeRef.current = studyType.DisplayValue;
                              }}
                              key={studyType.InternalValue}
                              value={studyType.InternalValue}
                              id={studyType.InternalValue}
                            >
                              {studyType.DisplayValue}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}

              {typeof masterData?.PATIENT_LEVEL_SHARING !== 'undefined' &&
                masterData?.PATIENT_LEVEL_SHARING?.length > 0 && (
                  <>
                    <h5>{additionalFilters.patientLevelData}</h5>
                    <div className={'patient-boxes'}>
                      <CheckboxSet
                        list={masterData?.PATIENT_LEVEL_SHARING}
                        name="PatientLevelSharing"
                      />
                    </div>
                  </>
                )}

              {typeof masterData?.RECSTATUS !== 'undefined' &&
                masterData?.RECSTATUS?.length > 0 && (
                  <>
                    <h5
                      onClick={() => {
                        setIsWeCanHelpOpen(!isWeCanHelpOpen);
                        trackAnalytics('FilterToggle', {
                          FilterName: 'Status',
                          Toggle: isWeCanHelpOpen ? 'off' : 'on',
                          PositionInList: 0,
                          CurrentValues: values.Status,
                        });
                      }}
                      className={'collapse-title'}
                    >
                      {'Status'}
                      {isWeCanHelpOpen ? (
                        <FaRegMinusSquare />
                      ) : (
                        <FaRegPlusSquare />
                      )}
                    </h5>
                    <Collapse isOpen={isWeCanHelpOpen}>
                      <div className={'status-boxes'}>
                        <CheckboxSet
                          list={masterData.RECSTATUS}
                          name="Status"
                        />
                      </div>
                    </Collapse>
                  </>
                )}

              {typeof masterData?.AGERANGE !== 'undefined' &&
                masterData?.AGERANGE?.length > 0 && (
                  <>
                    <h5
                      onClick={() => {
                        setIsAgeOpen(!isAgeOpen);
                        trackAnalytics('FilterToggle', {
                          FilterName: 'AgeRanges',
                          Toggle: isAgeOpen ? 'off' : 'on',
                          PositionInList: 1,
                          CurrentValues: values.AgeRanges,
                        });
                      }}
                      className={'collapse-title'}
                    >
                      {additionalFilters.ageRange}
                      {isAgeOpen ? <FaRegMinusSquare /> : <FaRegPlusSquare />}
                    </h5>
                    <Collapse isOpen={isAgeOpen}>
                      <div className={'age-boxes'}>
                        <CheckboxSet
                          list={masterData?.AGERANGE}
                          name="AgeRanges"
                        />
                      </div>
                    </Collapse>
                  </>
                )}

              {typeof masterData?.GENDER !== 'undefined' &&
                masterData?.GENDER?.length > 0 && (
                  <>
                    <h5
                      onClick={() => {
                        setIsGenderOpen(!isGenderOpen);
                        trackAnalytics('FilterToggle', {
                          FilterName: 'Gender',
                          Toggle: isGenderOpen ? 'off' : 'on',
                          PositionInList: 2,
                          CurrentValues: values.Gender,
                        });
                      }}
                      className={'collapse-title'}
                    >
                      {additionalFilters.gender}
                      {isGenderOpen ? (
                        <FaRegMinusSquare />
                      ) : (
                        <FaRegPlusSquare />
                      )}
                    </h5>
                    <Collapse isOpen={isGenderOpen}>
                      <div className={'gender-boxes'}>
                        <CheckboxSet list={masterData?.GENDER} name="Gender" />
                      </div>
                    </Collapse>
                  </>
                )}
              {typeof masterData?.PHASE !== 'undefined' &&
                masterData?.PHASE?.length > 0 && (
                  <>
                    <h5
                      onClick={() => {
                        setIsPhasesOpen(!isPhasesOpen);
                        trackAnalytics('FilterToggle', {
                          FilterName: 'Phases',
                          Toggle: isPhasesOpen ? 'off' : 'on',
                          PositionInList: 3,
                          CurrentValues: values.Phases,
                        });
                      }}
                      className={'collapse-title'}
                    >
                      {additionalFilters.phases}
                      {isPhasesOpen ? (
                        <FaRegMinusSquare />
                      ) : (
                        <FaRegPlusSquare />
                      )}
                    </h5>
                    <Collapse isOpen={isPhasesOpen}>
                      <div className={'phases-boxes2'}>
                        <CheckboxSet list={masterData?.PHASE} name="Phases" />
                      </div>
                    </Collapse>
                  </>
                )}

              {typeof masterData?.ATTACHTYPE !== 'undefined' &&
                masterData?.ATTACHTYPE?.length > 0 && (
                  <>
                    <h5
                      onClick={() => {
                        setIsTypeOpen(!isTypeOpen);
                        trackAnalytics('FilterToggle', {
                          FilterName: 'Documents',
                          Toggle: isTypeOpen ? 'off' : 'on',
                          PositionInList: 4,
                          CurrentValues: values.AttachmentTypes,
                        });
                      }}
                      className={'collapse-title'}
                    >
                      {additionalFilters.disclousureType}
                      {isTypeOpen ? <FaRegMinusSquare /> : <FaRegPlusSquare />}
                    </h5>
                    <Collapse isOpen={isTypeOpen}>
                      <div>
                        <CheckboxSet
                          list={masterData?.ATTACHTYPE.filter(
                            (_, index) => index < 5
                          )}
                          name="AttachmentTypes"
                        />
                      </div>
                    </Collapse>
                  </>
                )}
              <p className={'info-text'}>
                {
                  'To find a center near you, please provide Country or Postal Code'
                }
              </p>

              {typeof masterData?.COUNTRY !== 'undefined' &&
                masterData?.COUNTRY?.length > 0 && (
                  <>
                    <h5 className={'title country'}>
                      {additionalFilters.country}
                    </h5>
                    <Dropdown
                      isOpen={isCountryDropdownOpen}
                      toggle={() => {
                        setIsCountryDropdownOpen(!isCountryDropdownOpen);
                      }}
                    >
                      <DropdownToggle
                        tag="button"
                        type="button"
                        name="studyTypes"
                        className={'select-dropdown'}
                      >
                        <p>{countryRef.current || 'Select Country'}</p>
                        <span className={'arrowRight'}></span>
                      </DropdownToggle>
                      <DropdownMenu
                        flip={false}
                        direction="down"
                        style={{
                          maxHeight: '300px',
                          overflow: 'auto',
                        }}
                      >
                        {[
                          {
                            InternalValue: '',
                            DisplayValue: 'Select Country',
                          },
                        ]
                          .concat(masterData?.COUNTRY)
                          .map((country, index) => (
                            <DropdownItem
                              className={
                                countryRef.current === country.DisplayValue ||
                                (countryRef.current === '' && index === 0)
                                  ? 'selected'
                                  : ''
                              }
                              onClick={() => {
                                setFieldValue('country', country.InternalValue);
                                countryRef.current = country.DisplayValue;
                              }}
                              key={country.InternalValue}
                              value={country.InternalValue}
                              id={country.InternalValue}
                            >
                              {country.DisplayValue}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}

              <h5 className={'title'}>{additionalFilters.postalCode}</h5>
              <Field
                type="text"
                maxLength="10"
                name="zip"
                autoComplete="off"
                className={'zip'}
                aria-label={'zip label'}
              />
              {typeof masterData?.COLLABORATOR !== 'undefined' &&
                masterData?.COLLABORATOR.length > 0 && (
                  <>
                    <h5 className={'title'}>{'Collaborator'}</h5>
                    <Dropdown
                      direction="up"
                      isOpen={isCollaboratorDropdown}
                      toggle={() =>
                        setIsCollaboratorDropdown(!isCollaboratorDropdown)
                      }
                    >
                      <DropdownToggle
                        tag="button"
                        type="button"
                        name="Collaborators"
                        className={'select-dropdown'}
                      >
                        <p>
                          {collaboratorRef.current || 'Select Collaborator'}
                        </p>
                        <span className={'arrowRight'}></span>
                      </DropdownToggle>
                      <DropdownMenu
                        flip={false}
                        style={{
                          maxHeight: '300px',
                          height: '300px',
                          overflow: 'auto',
                          top: '-300px',
                        }}
                      >
                        {[
                          {
                            InternalValue: '',
                            DisplayValue: 'Select Collaborator',
                          },
                        ]
                          .concat(masterData?.COLLABORATOR)
                          .map((collaborator, index) => (
                            <DropdownItem
                              className={
                                collaboratorRef.current ===
                                  collaborator.DisplayValue ||
                                (collaboratorRef.current === '' && index === 0)
                                  ? 'selected'
                                  : ''
                              }
                              onClick={() => {
                                setFieldValue(
                                  'Collaborators',
                                  collaborator.InternalValue
                                );
                                collaboratorRef.current =
                                  collaborator.DisplayValue;
                              }}
                              key={collaborator.InternalValue}
                              value={collaborator.InternalValue}
                              id={collaborator.InternalValue}
                            >
                              {collaborator.DisplayValue}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}
              <div className={'action-sec'}>
                <button
                  type="button"
                  className={'resetBtn redBtn'}
                  onClick={() => {
                    Object.keys(formInitialValues).forEach(key => {
                      setFieldValue(key, formInitialValues[key]);
                    });
                    studyTypeRef.current = '';
                    collaboratorRef.current = '';
                    countryRef.current = '';
                    handleReset(formInitialValues);
                    setResetKey(true);
                  }}
                >
                  {'Reset'}
                </button>
                <button type={'submit'} className={'searchBtn redBtn'}>
                  {'Search Now'}
                </button>
              </div>
            </form>
          )}
        />
      )}
    </AdvancedStyle>
  );
};

AdvancedSearch.propTypes = {
  params: PropTypes.object,
  data: PropTypes.object.isRequired,
  searchCallBack: PropTypes.func,
  pageContext: PropTypes.object.isRequired,
};

const AdvancedSearchQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        studyRegisterJson {
          img
          imgAlt
          title
          description
          boldDescription
        }
        healthcarePartnersJson {
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        findAStudyJson {
          title
          subTitle
          searchTitle
          placeholder
          searchBtn
          collapseButton
          collapseQuestion
          choseMore
          collapseP1
          collapseP2
          studyStatus
          searchTooltip {
            childMarkdownRemark {
              html
            }
          }
        }
        additionalFiltersJson {
          title
          condition
          modalCondition {
            title
            healthyVolunteers
            selectedCondition
            clearBtn
            okBtn
          }
          country
          postalCode
          studyType
          patientLevelData
          ageRange
          gender
          phases
          disclousureType
          collaborators
          resetBtn
          searchBtn
        }
      }
    `}
    render={data => <AdvancedSearch data={data} {...props} />}
  />
);
export default AdvancedSearchQuery;
