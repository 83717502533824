import styled from 'styled-components';
import { pinkFont } from '../../constants/theme';

export const AdvancedStyle = styled.div`
  background-color: #f5f5f5;
  padding: 0 9px 40px 9px;
  margin: 30px 0;
  h3 {
    background-color: #e31c39;
    position: relative;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    margin: 0 -9px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #544f40;
    margin-top: 10px;
    &.collapse-title {
      cursor: pointer;
      margin-bottom: 8px;
      :hover {
        text-decoration: underline;
      }
    }
    svg {
      height: 14px;
      width: 14px;
    }
  }
  .keyword-search{
    justify-content: start;
    svg{
      font-size: 16px;
      color: ${pinkFont};
      margin-left: 5px;
      width: 16px;
      height: 16px;
    }
  }
  p.info-text {
    color: #00a0af;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    font-weight: 400;
    margin-top: 10px;
  }
  input {
    border-radius: 0;
    position: relative;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-right: 4px;
    border: 1px solid #ced4da;
    background-image: url(/icons/search-icon.png);
    background-position: 99% center;
    background-repeat: no-repeat;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 20px 4px 8px;
    color: #495057;
    ::-ms-clear {
      display: none;
    }
    :focus {
      border: 1px solid #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  select {
    border: 1px solid #ddd;
    padding: 4px 8px;
    font-size: 14px;
    width: 100%;
    color: #495057;
    &:focus {
      outline: none;
    }
  }
  .suggestions {
    z-index: 7;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 25px;
    right: 25px;
    background-color: white;
    list-style: none;
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    max-height: 300px;
    overflow: hidden;
    border: 1px solid;
    li {
      max-width: 1070px;
      width: 100%;
      margin-bottom: 6px;
      cursor: pointer;
    }
  }
  .patient-boxes {
    .radio-btn {
      label {
        color: rgb(84, 79, 64);
        font-size: 13px;
        line-height: 21px;
        font-weight: 600;
        word-break: break-word;
        width: 200px;
        padding-right: 0;
      }
      :hover {
        text-decoration: underline;
      }
    }
  }
  .modal-button {
    p {
      color: #495057 !important;
    }
  }
  .radio-btn {
    label {
      white-space: pre-wrap;
      padding-right: 20px;
    }
  }
  .select-dropdown {
    width: 100%;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 0 36px 0 10px;

    p {
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #495057;
    }
    .arrowRight {
      display: block;
      position: absolute;
      right: 4px;
      top: 0;
      width: 32px;
      height: 32px;
      background-color: #f8f8f8;
      color: #bbb;
      text-align: center;
      background: url(/icons/select-arrow.png) no-repeat center center;
    }
  }
  .zip {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #ced4da;
    background-image: none;
    border-radius: 0;
  }
  .dropdown-menu.show {
    margin-top: 0;
    width: 100%;
    .selected {
      background-color: ${pinkFont};
      color: white !important;
    }
    .dropdown-item {
      font-size: 12px;
      line-height: 20px;
      max-width: 100%;
      padding: 4px 10px;
      white-space: normal;
      color: #808080;
      &:hover {
        background-color: ${pinkFont};
        color: white;
      }
    }
  }
  .action-sec {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    .redBtn {
      background-color: #e31c39;
      color: #fff;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      padding: 8px 5px;
      font-size: 14px;
      border: 0;
      width: 90px;
      &:hover {
        background-color: #c11831;
        text-decoration: underline;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (max-width: 767px) {
    margin: 30px 0 15px 0;
    .action-sec {
      justify-content: flex-start;
      .redBtn {
        margin: 0 12px;
      }
    }
    .patient-boxes {
      .radio-btn {
        label {
          width: 300px;
        }
      }
    }
  }
`;
