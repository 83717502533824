export const useParseUrl = params => {
  return {
    Conditions:
      params.Conditions.length > 0 ? params.Conditions.split('~') : [],
    AgeRanges: params.AgeRanges.length > 0 ? params.AgeRanges.split('~') : [],
    Gender: params.Gender.length > 0 ? params.Gender.split('~') : [],
    Phases: params.Phases.length > 0 ? params.Phases.split('~') : [],
    SearchTerm: params.SearchTerm,
    StudyTypes: params.StudyTypes,
    Collaborators: params.Collaborators,
    PatientLevelSharing:
      params.PatientLevelSharing.length > 0
        ? params.PatientLevelSharing.split('~')
        : [],
    Status: params.Status.length > 0 ? params.Status.split('~') : [],
    AttachmentTypes:
      params.AttachmentTypes.length > 0
        ? params.AttachmentTypes.split('~')
        : [],
    HealthyVolunteer:
      params.HealthyVolunteer.length > 0
        ? params.HealthyVolunteer.split('~')
        : [],
    country: params.country,
    zip: params.zip,
  };
};
