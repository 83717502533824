import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactPaginate from 'react-paginate';

const ResultsPagination = ({
  reactPaginate,
  simple,
  onChange,
  activePage,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  if (simple) {
    let numberOfPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    return (
      <div className="simple-pagination">
        {activePage > 1 && (
          <button
            onClick={() => onChange && onChange(activePage - 1)}
            className="pagination-arrow left"
            type="button"
          >
            <IoIosArrowBack />
          </button>
        )}
        <div className={'counters'}>
          {activePage < numberOfPages ? (
            <span className="current-page">{activePage}</span>
          ) : (
            undefined
          )}
          {activePage < numberOfPages ? <em> - </em> : undefined}
          <span className="current-page">
            {numberOfPages > 1 ? numberOfPages : null}
          </span>
        </div>
        {activePage < numberOfPages && (
          <button
            onClick={() => onChange && onChange(activePage + 1)}
            className="pagination-arrow right"
            type="button"
          >
            <IoIosArrowForward />
          </button>
        )}
      </div>
    );
  } else if (reactPaginate) {
    return (
      <ReactPaginate
        previousLabel={' '}
        nextLabel={' '}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(totalItemsCount / itemsCountPerPage)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={activePage - 1}
      />
    );
  } else {
    return (
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        nextPageText={
          <>
            <span className="d-none">{'next'}</span>
            <i className={'next'} />
          </>
        }
        prevPageText={
          <>
            <span className="d-none">{'prev'}</span>
            <i className={'prev'} />
          </>
        }
        hideFirstLastPages={true}
        pageRangeDisplayed={
          typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 4
        }
        itemClass="page-item"
        linkClass="page-link"
        linkClassNext="text-link"
        linkClassPrev="text-link"
        onChange={onChange}
      />
    );
  }
};

ResultsPagination.propTypes = {
  reactPaginate: PropTypes.bool,
  simple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
};

export default ResultsPagination;
